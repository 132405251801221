import request from '../common/utils';


export const getEmployeesApi = ({ companyId }) => request({
  url: `companies/${companyId}/employees`,
  method: 'GET',
});

export const createEmployeeApi = ({ companyId, data }) => request({
  url: `companies/${companyId}/employees`,
  method: 'POST',
  data,
});

export const editEmployeeApi = ({ companyId, data }) => request({
  url: `companies/${companyId}/employees/${data.id}`,
  method: 'PUT',
  data,
});

export const deleteEmployeeApi = ({ companyId, id }) => request({
  url: `companies/${companyId}/employees/${id}`,
  method: 'DELETE',
});

export const setNewPasswordForEmployeeApi = ({ id, data }) => request({
  url: `employees/${id}/newPassword`,
  method: 'POST',
  data,
});
