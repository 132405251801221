import { useCallback, useState } from 'react';


const zustandAbortWrapper = (val, setState) => (payload) => {
  const ac = new AbortController();

  return ({
    abort: ac.abort.bind(ac),
    promise: val(payload, { signal: ac.signal, setState: setState(payload) }),
  });
};

export const abortController = (object, set, name) => (
  Object.entries(object).map(([key, val]) => {
    if (typeof val === 'function') {
      return [
        key,
        zustandAbortWrapper(val, (payload) => (data, more) => set(data, false, { type: `${name}/${key}${more ? `/${more}` : ''}`, payload })),
      ];
    }

    return [key, val];
  }).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {})
);

export const useStatus = (fn) => {
  const [status, setStatus] = useState();
  const func = useCallback((payload) => {
    setStatus('pending');

    const next = fn(payload);
    next
      .promise
      .then(() => {
        setStatus('fulfilled');
      })
      .finally(() => {
        setStatus((state) => (state === 'pending' ? 'error' : state));
      });

    return next;
  }, [fn]);

  return {
    fn: func,
    isPending: status === 'pending',
    isFulfilled: status === 'fulfilled',
    isError: status === 'error',
    status,
  };
};
