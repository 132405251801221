import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getEmiratesApi,
} from './api';


export const getEmirates = createAsyncThunk(
  'map/getEmirates',
  async (emirateId) => {
    const { data } = await getEmiratesApi({ emirateId });

    return data;
  },
);
