import React from 'react';
import { t } from 'i18next';

import Notification from 'components/Notification';
import { copyToBuffer } from 'components/utils';
import palette from 'palette';
import authStore from 'store/auth';
import commonStore from 'store/common';

import { c as CheckInCircle } from 'assets/svg/CheckInCircle.svg';
import { c as Alert } from 'assets/svg/Alert.svg';


const handleLogout = () => {
  Notification({
    image: <Alert fill={`rgba(${palette.uiColorsSystemYellow} / 1)`} height={32} width={32} />,
    title: t('ws.auth.logout'),
  });

  const { logout } = authStore.getState();

  logout();
};

const handleAppUpdate = () => {
  const { setAppIsUpdated } = commonStore.getState();

  setAppIsUpdated(true);
};

const closeCode = 4999;

class WS {
  instance = null;

  count = 0;

  store = null;

  token = null;

  constructor() {
    this.store = authStore;
    this.store.subscribe(this.handleChange.bind(null, authStore));
  }

  init = () => {
    const isDev = process.env.NODE_ENV === 'development';
    const { token } = this.store.getState();
    this?.instance?.close(closeCode);
    if (!token) return;
    this.instance = new WebSocket(`${isDev ? 'ws' : 'wss'}://${globalThis.location.host}/ws?bearer=${token}`);

    this.instance.addEventListener('open', this.onOpen);
    this.instance.addEventListener('close', this.onClose);
    this.instance.addEventListener('message', this.onMessage);
    // this.instance.addEventListener('error', this.onClose);

    return this;
  };

  onOpen = () => {
    this.count = 0;
    console.log('WS Connected...'); // eslint-disable-line
  };

  onClose = (e) => {
    const { code } = e;
    if (code === closeCode) return;
    this.count += 1;
    const delay = Math.min(2 ** this.count, 10);
    const { dispatch } = this.store;

    if (this.count > 10) {
      handleLogout(dispatch);
    }

    setTimeout(() => {
      this.init(this.store); // eslint-disable-line
    }, delay * 1000);
  };

  onMessage = (e) => { // eslint-disable-line
    const res = JSON.parse(e.data);

    switch (res.event) {
    case 'catalog_generated':
      Notification({
        image: <CheckInCircle fill={`rgba(${palette.uiColorsSystemGreen} / 1)`} height={32} width={32} />,
        title: t(`ws.catalog.${res.event}`),
        interactive: (
          <>
            <a href={res.data.link} target="_blank" rel="noreferrer">{t('Download PDF')}</a>
            <span> | </span>
            <a onClick={() => copyToBuffer(res.data.link)}>{t('Copy link')}</a> {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
          </>
        ),
      }, { autoClose: false });
      break;

    case 'logout':
      handleLogout();
      break;

    case 'web_client_updated':
      handleAppUpdate();
      break;

    default:
      break;
    }
  };

  handleChange = () => {
    const prev = this.token;
    this.token = this.store.getState().token;

    if (prev !== this.token) this.init();
  };
}

export default WS;
