import { createSlice } from '@reduxjs/toolkit';

import {
  getCompanies,
  createCompany,
  editCompany,
  deleteCompany,
  getCompany,
  updateUnit,
  createUnit,
  getResaleCompanies,
  getResaleCompanyUnitsForAdmins,
  getCompanyResaleUnitsForBrokers,
  declineResaleUnit,
  publishResaleUnit,
  deleteUnit,
} from './thunks';


const initialState = {
  data: [],
  company: null,
  resaleCompanies: [],
  meta: {},
  error: null,
};

const reducers = {};

const extraReducers = (builder) => builder
  .addCase(getCompanies.fulfilled, (state, { payload }) => {
    state.data = payload;
  })

  .addCase(getCompany.fulfilled, (state, { payload }) => {
    state.company = payload;
  })

  .addCase(createCompany.fulfilled, (state, { payload }) => {
    state.data.push(payload);
  })

  .addCase(editCompany.fulfilled, (state, { payload }) => {
    const index = state.data.findIndex(({ id }) => id === payload.id);
    state.data[index] = payload;
  })

  .addCase(deleteCompany.fulfilled, (state, { payload }) => {
    state.data = state.data.filter(({ id }) => id !== Number(payload));
  })

  .addCase(getResaleCompanies.fulfilled, (state, { payload }) => {
    state.resaleCompanies = payload;
  })

  .addCase(getResaleCompanyUnitsForAdmins.fulfilled, (state, { payload }) => {
    state.company = {
      ...(state.company || {}),
      units: payload?.units,
    };
  })

  .addCase(getCompanyResaleUnitsForBrokers.fulfilled, (state, { payload }) => {
    state.company = {
      ...(state.company || {}),
      units: payload,
    };
  })

  .addCase(createUnit.fulfilled, (state, { payload }) => {
    if (payload.typeOfSale === 'resale') state.company.units.push(payload);
  })

  .addCase(deleteUnit.fulfilled, (state, { payload }) => {
    if (state?.company?.units) {
      state.company.units = state.company.units.filter(({ id }) => id !== payload.id);
    }
  })

  .addCase(updateUnit.fulfilled, (state, { payload }) => {
    if (state?.company?.units) {
      state.company.units = state.company.units.map((item) => (item.id === payload.id ? payload : item));
    }
  })

  .addCase(declineResaleUnit.fulfilled, (state, { payload }) => {
    state.company.units = state.company.units.map((item) => (item.id === payload.id ? payload : item));
  })

  .addCase(publishResaleUnit.fulfilled, (state, { payload }) => {
    state.company.units = state.company.units.map((item) => (item.id === payload.id ? payload : item));
  });

export const { actions, reducer, name } = createSlice({
  name: 'companies',
  initialState,
  reducers,
  extraReducers,
});
