import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  attachFilesApi,
  uploadFileApi,
  deleteFileLinkApi,
  updateFileApi,
} from './api';


export const uploadFile = createAsyncThunk(
  'file/upload',
  async ({ additionalInfo, file }) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('additionalInfo', JSON.stringify(additionalInfo));

    const { data } = await uploadFileApi(formData);

    return data;
  },
);

export const uploadFiles = createAsyncThunk(
  'files/upload',
  async ({ files }) => {
    const res = await Promise.all(files?.map(async (file) => {
      const formData = new FormData();

      formData.append('file', file);
      const { data } = await uploadFileApi(formData);

      return data;
    }));

    return res;
  },
);

export const attachFiles = createAsyncThunk(
  'files/attach',
  async (items) => {
    /** item = {
     *   modelId: number,
     *   modelType: 'unit',
     *   id: file.fileId,
     * }
     */
    const data = await attachFilesApi(items);
    return data;
  },
);

export const uploadAndAttachFiles = createAsyncThunk(
  'files/attach',
  async ({
    items,
    modelId,
    modelType,
    scope,
  }, { dispatch }) => {
    const data = await dispatch(uploadFiles({ files: items.map(({ file }) => file) }))
      .then(({ payload }) => payload.map((item, i) => ({
        ...item,
        ...items[i],
        file: undefined,
        modelId,
        modelType,
        scope,
        id: item.fileId,
      })))
      .then(async (res) => {
        await attachFilesApi(res);

        return res;
      });

    return data;
  },
);

export const updateFiles = createAsyncThunk(
  'files/update',
  async ({ items }) => {
    const data = await Promise.all(items.map((item) => updateFileApi(item)));

    return data.map((item) => item.data);
  },
);

export const deleteFileLink = createAsyncThunk(
  'files/deleteLink',
  async ({ ids }) => {
    const { data } = await Promise.all(ids.map((id) => deleteFileLinkApi({ pivotId: id })));

    return data;
  },
);
