import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { abortController } from 'store/utils';

import { setLocalStorage } from './utils';


const role = localStorage.getItem('role');
const mapType = localStorage.getItem('mapType');

export default create(devtools(
  immer((set) => abortController({
    appIsUpdated: false,
    mapType: role === 'admin' ? 'TGMap' : mapType || 'OLMap',

    setAppIsUpdated: (payload, { setState }) => {
      setState({ appIsUpdated: payload });
    },

    setMapType: (payload, { setState }) => {
      setState({ mapType: payload });
      setLocalStorage('mapType', payload);
    },
  }, set, 'Common')),
  {
    name: 'Common',
  },
));
