import { createSlice } from '@reduxjs/toolkit';

import {
  getEmirates,
} from './thunks';


const initialState = {
  data: [],
  meta: {},
  error: null,
};

const reducers = {};

const extraReducers = (builder) => builder
  .addCase(getEmirates.fulfilled, (state, { payload }) => {
    state.data = payload;
  });

export const { actions, reducer, name } = createSlice({
  name: 'emirates',
  initialState,
  reducers,
  extraReducers,
});
