import { createAsyncThunk, createNextState } from '@reduxjs/toolkit';

import {
  createCatalogApi,
  editCatalogApi,
  generateCatalogApi,
  getCatalogApi,
  getCatalogsApi,
  publishCatalogApi,
} from './api';


export const getCatalog = createAsyncThunk(
  'catalog/get',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await getCatalogApi(id);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getCatalogs = createAsyncThunk(
  'catalog/get',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getCatalogsApi();

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const createCatalog = createAsyncThunk(
  'catalog/create',
  async (catalog, { rejectWithValue }) => {
    try {
      const catalogForSave = createNextState(catalog, (draft) => {
        draft.districts.forEach((district) => {
          district.projects.forEach((project) => {
            project.units = project.units.map(({ id }) => id);
          });
        });
      });

      const { data } = await createCatalogApi(catalogForSave);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const editCatalog = createAsyncThunk(
  'catalog/edit',
  async (catalog, { rejectWithValue }) => {
    try {
      const catalogForSave = createNextState(catalog, (draft) => {
        draft.districts.forEach((district) => {
          district.projects.forEach((project) => {
            project.units = project.units.map(({ id }) => id);
          });
        });
      });

      const { data } = await editCatalogApi(catalogForSave);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const publishCatalog = createAsyncThunk(
  'catalog/publish',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await publishCatalogApi(id);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const generateCatalog = createAsyncThunk(
  'catalog/generate',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await generateCatalogApi(id);

      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  },
);
