import { createSlice } from '@reduxjs/toolkit';

import {
  getEmployees,
  createEmployee,
  editEmployee,
  deleteEmployee,
} from './thunks';


const initialState = {
  data: {
    map: {},
    list: [],
  },
  meta: {},
  error: null,
};

const reducers = {};

const extraReducers = (builder) => builder
  .addCase(getEmployees.fulfilled, ({ data }, { payload }) => {
    data.map = payload.reduce((acc, item) => ({ ...acc, [Number(item.id)]: item }), {});
    data.list = Object.keys(data.map).map(Number);
  })

  .addCase(createEmployee.fulfilled, ({ data }, { payload }) => {
    data.map[payload.id] = payload;
    data.list.push(payload.id);
  })

  .addCase(editEmployee.fulfilled, ({ data }, { payload }) => {
    data.map[payload.id] = payload;
  })

  .addCase(deleteEmployee.fulfilled, ({ data }, { payload }) => {
    delete data.map[payload];
    data.list = data.list.filter((item) => item !== Number(payload));
  });

export const { actions, reducer, name } = createSlice({
  name: 'employees',
  initialState,
  reducers,
  extraReducers,
});
