import request from '../common/utils';


export const loginApi = ({ email, password }) => request({
  url: '/login',
  method: 'POST',
  data: { email, password },
});

export const logoutApi = () => request({
  url: '/logout',
  method: 'POST',
});

export const userApi = (signal) => request({
  url: '/me',
  method: 'get',
  signal,
});

export const forgotPasswordApi = (data) => request({
  url: '/forgot-password',
  method: 'post',
  data,
});

export const resetPasswordApi = (data) => request({
  url: '/reset-password',
  method: 'post',
  data,
});
