import React from 'react';
import { element, string, oneOfType } from 'prop-types';
import { toast } from 'react-toastify';

import styles from './Notification.css';


const Notification = ({
  image,
  title,
  subTitle,
  interactive,
}) => (
  <div className={styles.notification}>
    <div className={styles.image}>
      {typeof image === 'string'
        ? <img alt="" src={image} />
        : image}
    </div>
    <div className={styles.body}>
      {title && <div className={styles.title}>{title}</div>}
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      {interactive && <div className={styles.interactive}>{interactive}</div>}
    </div>
  </div>
);

Notification.propTypes = {
  image: oneOfType([element, string]),
  title: string,
  subTitle: string,
  interactive: element,
};

Notification.defaultProps = {
  image: undefined,
  title: undefined,
  subTitle: undefined,
  interactive: undefined,
};

export default (myProps, toastProps) => toast(<Notification {...myProps} />, { ...toastProps });
