import { lazy } from 'react';


export default [
  { path: '/', element: lazy(() => import('containers/pages/Main')) },
  { path: '/login', element: lazy(() => import('containers/pages/Login')) },
  { path: '/reset-password', element: lazy(() => import('containers/pages/ResetPassword')) },
  { path: '/personal/*', element: lazy(() => import('containers/pages/Personal')) },
  { path: '/preview', element: lazy(() => import('containers/pages/Preview')) },
];
