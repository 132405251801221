import React from 'react';
import Lottie from 'lottie-react';

import LoaderDark from './LoaderDark.json';
import styles from './Loader.scss';


const Loader = () => (
  <div className={`loader ${styles.loader}`}>
    <Lottie animationData={LoaderDark} />
  </div>
);

export default Loader;
