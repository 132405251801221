import request from '../common/utils';


export const getCatalogsApi = () => request({
  url: '/catalogs',
  method: 'GET',
});

export const getCatalogApi = (id) => request({
  url: `/catalogs/${id}`,
  method: 'GET',
});

export const createCatalogApi = (catalog) => request({
  url: '/catalogs',
  method: 'POST',
  data: catalog,
});

export const editCatalogApi = (catalog) => request({
  url: `/catalogs/${catalog.id}`,
  method: 'PUT',
  data: catalog,
});

export const publishCatalogApi = (id) => request({
  url: `/catalogs/${id}/publish`,
  method: 'POST',
});

export const generateCatalogApi = (id) => request({
  url: `/catalogs/${id}/generate`,
  method: 'POST',
});

