import axios from 'axios';


const instance = axios.create({
  baseURL: '/api/v1',
});

instance.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 401 && window.location.pathname !== '/login') {
    window.location.href = '/login';
  }

  throw error;
});

const setLanguage = (language) => {
  instance.defaults.headers.common['Accept-Language'] = language;
  instance.defaults.headers.common['X-API-Locale'] = language;
};

const setToken = (token) => {
  instance.defaults.headers.common.Authorization = token;
};

export default instance;

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export {
  setToken,
  setLanguage,
};
