module.exports = {
  uiColorsSystemBlack: '0 17 29',
  uiColorsSystemGrey1: '153 155 174',
  uiColorsSystemGrey2: '183 188 201',
  uiColorsSystemGrey3: '247 247 247',
  uiColorsSystemGrey4: '247 247 247',
  uiColorsSystemWhite: '255 255 255',
  uiColorsSystemRed: '255 71 71',
  uiColorsSystemYellow: '255 177 64',
  uiColorsSystemBlue: '88 85 252',
  uiColorsSystemGreen: '34 197 94',
  uiColorsSystemPink: '186 143 255',
  uiColorsPictonBlue: '67 173 239',
  uiColorsUltraPink: '255 121 250',
};
