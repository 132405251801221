import i18next from 'i18next';


export const transfotmDescription = (item) => {
  const { description } = item;

  if (description === '') {
    return { ...item, description: undefined };
  }

  if (description instanceof Object) return item;

  const lang = i18next.language;

  return {
    ...item,
    description: {
      ru: '',
      en: '',
      [lang]: description,
    },
  };
};
